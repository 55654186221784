import { useFetcher } from "@remix-run/react";
import { useCallback } from "react";
import type { FavoriteContactProduct } from "~types/api/favorite.types";

import { useManagedFetcher } from "./use-managed-fetcher";

export const useFavorites = ({
  contactId,
  favorites,
}: {
  contactId: number | null;
  favorites: FavoriteContactProduct[];
}) => {
  const favoritesFetcher = useFetcher<any>();

  const [isFavoritesFetcherLoading, isFavoritesFetcherDone, isFavoritesFetcherFailed] =
    useManagedFetcher({
      fetcher: favoritesFetcher,
      toastSuccessMessage: favoritesFetcher.data?.message || "Favoris mis à jour",
    });

  const handleFavoritesChange = useCallback(
    ({ productId, checked }: { productId: number; checked: boolean }) => {
      favoritesFetcher.submit(
        {
          productId: productId.toString(),
          contactId: contactId?.toString() || "",
          checked: checked.toString(),
        },
        {
          method: "post",
          action: "/account/favorites/update",
        }
      );
    },
    [favoritesFetcher, contactId]
  );

  const isInFavorites = useCallback(
    (productId: number) => {
      return favorites.some((favorite) => favorite.product === `/api/products/${productId}`);
    },
    [favorites]
  );

  return {
    isInFavorites,
    handleFavoritesChange,
    isFavoritesFetcherLoading,
    isFavoritesFetcherDone,
    isFavoritesFetcherFailed,
  };
};
